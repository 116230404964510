/* Hide scrollbar but still allow scrolling */
.no-scroll {
  overflow: hidden;
}

.hide-scrollbar {
  scrollbar-width: none; /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Edge */
}

.element {
  forced-color-adjust: none;
}